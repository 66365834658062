import { Line } from 'vue-chartjs';
import chartColors from '@/mixins/chartColors';
// import * as pattern from 'patternomaly'

export default {
  extends: Line,
  mixins: [chartColors],
  props: {
    chartData: { type: Object },
    options: { type: Object },
  },

  data() {
    return {
      gradients: [],
    };
  },

  mounted() {
    this.colorize();
    this.renderChart(this.chartData, this.options);
  },

  methods: {
    createGradient() {
      const gradientBackground = this.$refs.canvas
        .getContext('2d')
        .createLinearGradient(0, 0, 0, 260);
      gradientBackground.addColorStop(0, `rgba(47, 128, 237, 0.4)`);
      gradientBackground.addColorStop(1, `rgba(47, 128, 237, 0)`);
      const gradientsLength = this.gradients.push(gradientBackground);
      return gradientsLength - 1;
    },

    colorize() {
      if (this.chartData && this.chartData.datasets) {
        this.chartData.datasets = this.chartData.datasets.map((s) => {
          const color = s.backgroundColor || '#5F9BED';

          if (s.noGradient) {
            return {
              ...s,
              borderColor: color,
              backgroundColor: 'transparent',
              pointRadius: 0,
            };
          }
          const gradientIndex = this.createGradient(color);
          return {
            ...s,
            borderColor: color,
            backgroundColor: this.gradients[gradientIndex],
            pointRadius: 0,
          };
        });
      }
    },
  },

  watch: {
    chartData: {
      handler() {
        this.colorize();
        if (this.$data._chart) {
          this.$data._chart.destroy();
          this.$emit('chart:destroy');
        }
        this.renderChart(this.chartData, this.options);
        this.$emit('chart:render');
      },
    },
  },
};
